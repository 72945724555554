import React from 'react';

import classnames from 'classnames';

import PropTypes from 'prop-types';

export default function EmptySlot({
  isChanging,
}: {
  isChanging: boolean,
}) {
  return (
    <div
      className={classnames(
        'equipment__empty-slot',
        isChanging && 'equipment__empty-slot--is-changing',
      )}
      style={{
        width: '40px',
        height: '40px',
      }}
    />
  );
}

EmptySlot.displayName = 'EmptySlot';

EmptySlot.propTypes = {
  isChanging: PropTypes.bool.isRequired,
};
